import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`Denna veckan samtalar vi om etik kring namngivning i mjukvarubranchen och uppdaterar kring nya versioner av några av våra favoritapplikationer. Vi tar också en titt på varför Google har blivit stämda på 5 miljarder dollar och avslutar med att tipsa om ett verktyg som hjälper dig att fuska (på ett trevligt sätt!)`}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Blacklist/slave/master: `}<a parentName="li" {...{
          "href": "https://ma.ttias.be/cronweekly/issue-138/"
        }}>{`https://ma.ttias.be/cronweekly/issue-138/`}</a>{` `}</li>
      <li parentName="ul">{`Plasma 5.19: `}<a parentName="li" {...{
          "href": "https://kde.org/announcements/plasma-5.19.0"
        }}>{`https://kde.org/announcements/plasma-5.19.0`}</a></li>
      <li parentName="ul">{`Linux 5.8-rc1: `}<a parentName="li" {...{
          "href": "https://lkml.org/lkml/2020/6/14/253"
        }}>{`https://lkml.org/lkml/2020/6/14/253`}</a></li>
      <li parentName="ul">{`Gimp 3.10.20: `}<a parentName="li" {...{
          "href": "https://www.gimp.org/news/2020/06/11/gimp-2-10-20-released/"
        }}>{`https://www.gimp.org/news/2020/06/11/gimp-2-10-20-released/`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Google stämda, $5.000.000.000: `}<a parentName="li" {...{
          "href": "https://www.vice.com/en_au/article/m7jvvp/google-sued-for-5-billion-for-tracking-users-even-those-using-incognito-private-mode"
        }}>{`https://www.vice.com/en_au/article/m7jvvp/google-sued-for-5-billion-for-tracking-users-even-those-using-incognito-private-mode`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Cheat: `}<a parentName="li" {...{
          "href": "https://github.com/cheat/cheat"
        }}>{`https://github.com/cheat/cheat`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <h3>{`Sebs Linuxäventyr`}</h3>
    <ul>
      <li parentName="ul">{`Lära mig touch typing: `}<a parentName="li" {...{
          "href": "https://www.keybr.com/"
        }}>{`https://www.keybr.com/`}</a>{` `}</li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Isak frågar efter XMPP-kanal`}</li>
      <li parentName="ul">{`Lämna gärna en recension`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0 (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      